/* Custom CSS for the IonSelect pop-up */
.custom-ion-select .select-interface-option {
    width: 80vw;
    max-width: 80vw;
}

.custom-ion-select .alert-wrapper {
    width: 80vw;
    max-width: 80vw;
}

.custom-ion-select .alert-radio-label {
    white-space: normal;
}
@import "~bootstrap/scss/bootstrap";




.header-logo {
  position: absolute;
  width: 100%;
  left: 0;
}

.header-logo > img {
  display: block;
}


.cst-img-margin {
  margin: 0.1rem auto 0.1rem auto;
  object-fit: contain;
}
.rounded{
  border-radius: 8px !important;
}

.text-sm{
  font-size: 12px;
}

.text-md{
  font-size: 14px;
}

.text-lg{
  font-size: 18px;
}

.icon-lg{
  font-size: 45px;
}

.dashboard-grid{
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.bg-light{
  background-color: #f4f5f8 !important;
}

.grid-1fr-a {
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  grid-template-columns: 100%;
}

.grid-a-1fr-a {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  grid-template-columns: 100%;
}

.grid-a-1fr {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: 100%;
}


.d-grid{
  display: grid !important;
  &.center{
    > * {
      align-self: center !important;
    }
  }
  > * {
    min-width: 0;
  }
  &.g-a-1-a{
    grid-template-columns: auto 1fr auto;
  }
  &.g-1-1-1-1{
    grid-template: 1fr 1fr / 1fr 1fr;
    grid-gap: 10px;
  }
}

.border.thick{
  border-width: 2px !important;
}

.p-square{
  padding-bottom: 100% !important;
}


canvas {
  width: 100%!important;
  height: auto !important;
}

.alert-radio-label {
  white-space: normal !important;
}

.alert-checkbox-label {
  white-space: normal !important;
}


.contact-popover .popover-content{ --width: 320px; }

.alert-wrapper {
  min-width: 400px;
  width: 280px !important;
}

.bg-primary-force {
  background-color: #ffc409 !important;
}

.text-primary-account {
  color: #ffc409 !important;
}


.webPage{
  width: 100%;
  height: 100%;
  border: none;
}


/* styles.css */
/* Modal styling */
ion-modal#forgot-password-modal {
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
}

/* Content styling */
ion-modal#forgot-password-modal ion-content {
  --ion-padding: 20px; /* Adjust padding as needed */
}

/* Backdrop styling */
ion-modal#forgot-password-modal::part(backdrop) {
  background: rgba(209, 213, 219, 0.8); /* Adjust backdrop color and opacity */
}

